<template>
    <div class="ml-10 mr-10">
        <TitleBar>{{ $t("menu.documents") }}
            <!--            <v-btn-->
            <!--                class="mx-2"-->
            <!--                fab-->
            <!--                small-->
            <!--                color="secondary"-->
            <!--                to="/accounts/new"-->
            <!--            >-->
            <!--                <v-icon>-->
            <!--                    mdi-plus-->
            <!--                </v-icon>-->
            <!--            </v-btn>-->
        </TitleBar>

        <List :fields="['name', 'mimetype', 'created_at']" api-key="documents" api-url="/documents"></List>

    </div>
</template>

<script>

import List from "../../components/List";
import TitleBar from "@/components/TitleBar.vue";

export default {
    name: "Dashboard",
    components: {TitleBar, List},
    mounted() {
        this.$store.commit("SET_BREADCRUMBS", [
            {
                text: this.$t("menu.home"),
                to: "/",
                exact: true
            },
            {
                text: this.$t("menu.documents"),
                to: "/documents",
                exact: true
            }
        ]);
    }
};
</script>

<style scoped>

</style>